@media (min-width: 1025px) {
  .wrapper {
    height: $header-size - $header-margin - 1px;
    margin-top: $header-margin;
  }
}

.jackpots__title {
  display: none;
}

.home__wheel-fortune-wrapper--no-jackpots {
  top: 46px;
  @media screen and (max-width: 1024px) {
    top: 48px;
  }
}

.wheel-fortune__page {
  .logo {
    top: 37%;
  }
}
