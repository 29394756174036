.logo {
  background-size: 75% auto;

  &--wide {
    background-size: auto 60%;

    @media (orientation: portrait) and (max-width: 1080px) {
      background-size: 60% auto;
    }

    @media (orientation: landscape) and (max-height: 511px) {
      max-height: 140px !important;
      background-size: 60% auto;
    }
  }

  &--full {
    background-size: 80% auto;

    @media (orientation: landscape) {
      background-size: 80% auto;
    }
  }

  @media (max-width: 1080px) {
    &:not(&--wide) {
      width: 15%;
    }
  }

  @media (max-width: 1150px) {
    &:not(&--wide) {
      width: 19%;
    }
  }

  @media (min-width: 1025px) {
    height: $header-size - $header-margin - 1px;
  }
}
